<template>
  <div class="filter-promopushka" id="filter-promopushka" v-if="isEnable()">
    <div class="filter-promopushka__search">
      <label class="form-label">Поисковый запрос</label>
      <div class="row g-2">
        <div class="col">
          <div class="input-icon">
            <input @keyup.enter="events.onSearch" type="text" v-model="bootData.search" class="form-control" placeholder="Напишите что-нибудь">
            <button class="btn input-icon-addon__button" @click="events.onSearch">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-promopushka__date">
      <label class="form-label">Дата</label>
      <Datepicker auto-apply v-model="bootData.date" @update:modelValue="events.applyDate" range multi-calendars locale="ru" format="dd.MM.yyyy" placeholder="Выбрать дату" :enable-time-picker="false" />
    </div>

    <div class="filter-promopushka__select users">
      <label class="form-label">Магазин</label>
      <Multiselect @change="events.onShop" v-model="bootData.shops" mode="tags" placeholder="Не выбрано" noResultsText="Результатов не найдено" :searchable="true" :close-on-select="true" :options="variantsData.shops" :trackBy="'title'">
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
            <img :src="option.image">
            <div class="user-name">{{ option.title }}</div>
            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
        <template v-slot:option="{ option }">
          <div class="option-user">
            <div class="user-image-wrap">
              <img class="user-image" :src="option.image">
            </div>
            <div class="user-name"> {{ option.title }}</div>
          </div>
        </template>
      </Multiselect>
    </div>

    <div class="filter-promopushka__select users">
      <label class="form-label">Пользователь</label>
      <Multiselect @change="events.onUser" v-model="bootData.users" mode="tags" placeholder="Не выбрано" noResultsText="Результатов не найдено" :close-on-select="true" :options="variantsData.users" :searchable="true" :trackBy="'name'">
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
            <img :src="option.image">
            <div class="user-name">{{ option.name }}</div>
            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
        <template v-slot:option="{ option }">
          <div class="option-user">
            <div class="user-image-wrap">
              <img class="user-image" :src="option.image">
            </div>
            <div class="user-name"> {{ option.name }}</div>
          </div>
        </template>
      </Multiselect>
    </div>

    <div class="filter-promopushka__buttons">
      <label class="form-label">Статус</label>
      <div class="form-selectgroup">
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="bootData.statuses.enabled" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sun me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="4"></circle>
              <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
            </svg>
            Активно
          </span>
        </label>
        <label class="form-selectgroup-item">
          <input @change="events.onStatuses" type="checkbox" v-model="bootData.statuses.removed" class="form-selectgroup-input">
          <span class="form-selectgroup-label">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="4" y1="7" x2="20" y2="7"></line>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
            </svg>
            Удалено
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, reactive} from "vue"
import Multiselect from "@/plugins/multiselect"
import Datepicker from "@/plugins/datepicker"
import Api from "@/modules/promopushka/components/filter/services/Api"
import Image from "@/services/Image"
import DT from "@/services/DT"
import {useRoute} from "vue-router/dist/vue-router"

export default defineComponent({
  name: "Filter",
  components: {
    Datepicker,
    Multiselect,
  },
  props: {
    enable: {
      type: Boolean,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    const route = useRoute()

    let bootData = reactive({
      search: props.params.search ?? '',
      date: props.params.date ?? null,
      users: props.params.users ?? [],
      shops: [],
      statuses: {
        enabled: props.params.statuses.enabled ?? true,
        disabled: props.params.statuses.disabled ?? false,
        removed: props.params.statuses.removed ?? false,
      },
    })

    const variantsData = reactive({
      users: [],
      shops: [],
    })

    const events = {
      applyDate: () => {
        onFilter()
      },
      onUser: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        bootData.users = value
        onFilter()
      },
      onShop: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        bootData.shops = value
        onFilter()
      },
      onSearch: () => {
        onFilter()
      },
      onStatuses: () => {
        onFilter()
      }
    }

    onMounted(() => {
      users()
      shops().then(() => {
        if (props.params.shops.length > 0) {
          (() => {
            bootData.shops = props.params.shops
          })().then(() => {
            onFilter()
          });
        } else {
          onFilter()
        }
      })
    })

    function users() {
      return Api.users().then((resp) => {
        usersMultiSelect(resp)
      })
    }

    async function shops() {
      return Api.shops().then((resp) => {
        shopsMultiSelect(resp)
      })
    }

    function onFilter() {
      pushParent()
    }

    function usersMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.users.push({
          value: obj["id"],
          name: obj["name"],
          image: Image.handle(obj["avatar_host"], obj["avatar_name"], 60)
        })
      }
    }

    function shopsMultiSelect(items) {
      for (let prop in items) {
        let obj = items[prop]

        variantsData.shops.push({
          value: obj["id"],
          title: obj["title"],
          image: Image.handle(obj["host"], obj["image"], 60),
        })
      }
    }

    function pushParent() {
      emit("onFilter", getResult())
    }

    function getResult() {
      let date = forDateResult(bootData.date)      

      return {
        search: bootData.search,
        date: {
          from: date[0],
          to: date[1],
        },
        users: bootData.users,
        shops: bootData.shops,
        statuses: {
          enabled: bootData.statuses.enabled,
          disabled: bootData.statuses.disabled,
          removed: bootData.statuses.removed
        },
      }
    }

    function forDateResult(date) {
      if (date === null) {
        return [
          null,
          null,
        ]
      }

      let from = new DT(date[0]).startTimeOfDay()
      let to = new DT(date[1]).endTimeOfDay()

      from.setMonth(from.getMonthNatural())
      to.setMonth(to.getMonthNatural())

      return [
        from.get("%Y-%M-%D %H:%I:%S"),
        to.get("%Y-%M-%D %H:%I:%S"),
      ]
    }

    function isEnable() {
      return props.enable === true
    }

    return {
      events,
      bootData,
      variantsData,
      isEnable,
      route
    }
  }
})
</script>

<style scoped></style>
