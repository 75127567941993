import {axios as http} from "@/plugins/http"

export default class ApiBase {
    static item(query) {
        return http.post("/api/v1/promopushka/coupon", query);
    }

    static items(query) {
        return http.post("/api/v1/promopushka/coupon", query);
    }
    
    static create(query) {
        return http.post("/api/v1/promopushka/coupon/create", query);
    }
    
    static update(query) {
        return http.post("/api/v1/promopushka/coupon/update", query);
    }
    
    static delete(query) {
        return http.post("/api/v1/promopushka/coupon/delete", query);
    }

    static uploadCouponList(query) {
        return http.post("/api/v1/promopushka/coupon-list/upload", query);
    }

    static statCouponList(query) {
        return http.post("/api/v1/promopushka/coupon-list/stat", query);
    }

    static deleteCouponList(query) {
        return http.post("/api/v1/promopushka/coupon-list/delete", query);
    }

    static users() {
        return http.post("/api/v1/promopushka/users");
    }

    static shop() {
        return http.post("/api/v1/promopushka/shop");
    }

    static shopCoupons(query) {
        return http.post("/api/v1/promopushka/shop/coupons", query);
    }

    static shopCategories(query) {
        let config = {
            params: query,
        }
        return http.get("/api/v1/shop/shop/categories", config);
    }

    static shops(query) {
        let config = {
            params: query,
        }
        return http.get("/api/v1/shop/shop/items", config);
    }
}
